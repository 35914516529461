<template>
<div class="blue-grey lighten-5 py-5 mx-0 text-center">
  <div class="text-h5 mb-3">{{ $t("view.PageMainHome.slide_syllabus_title") }}</div>
  <!-- <v-row>
    <v-spacer></v-spacer>
    <v-col sm="5">
      <v-card
        :to="{name:'PageMainDiscover'}"
      >
        <v-card-text class="primary--text">{{ $t("view.PageMainHome.slide_syllabus_name1") }}</v-card-text>
      </v-card>
    </v-col>
    <v-spacer></v-spacer>
  </v-row> -->
  <v-row>
    <v-spacer class="d-none d-sm-block"></v-spacer>
    <v-col sm="6" class="px-5">
      <DropdownSyllabus
        ref="dropdown_syllabus"
        :callbackSelect="selectSyllabus"
        :callbackError="showError"
      ></DropdownSyllabus>
    </v-col>
    <v-col sm="3" class="px-5">
      <v-btn
        block
        x-large
        :to="{name:'PageMainDiscover',query:{s:key}}"
      >{{$t('action.search')}}</v-btn>
    </v-col>
    <v-spacer class="d-none d-sm-block"></v-spacer>
  </v-row>
</div>
</template>

<script>
  import DropdownSyllabus from '@/components/DropdownSyllabusV2'
  import { mapState } from 'vuex'
  export default {
    components:{
      DropdownSyllabus,
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      key:null
    }),
    created() {
      //
    },
    mounted() {
      this.$refs['dropdown_syllabus'].fetch()
    },
    methods: {
      selectSyllabus(val){
        this.key = val.key
      },
      showError(){
        //
      },
    }
  }
</script>