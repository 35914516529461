<template>
  <div @click="getLink" class="create">
    <v-speed-dial
      v-model="fab"
      :top="top"
      :bottom="bottom"
      :right="right"
      :left="left"
      :direction="direction"
      :open-on-hover="hover"
      :transition="transition"
    >
      <template v-slot:activator>
        <v-fab-transition>
          <v-btn
            small
            v-model="fab"
            color="green"
            fab
            dark
            bottom
            left
            class="v-btn--example"
          >
            <v-icon small v-if="fab"> mdi-close </v-icon>
            <v-icon small v-else> mdi-share-variant </v-icon>
          </v-btn>
        </v-fab-transition>
      </template>
      <ShareNetwork
        class="text-decoration-none"
        network="whatsapp"
        :title="title"
        :url="currentUrl"
      >
        <v-btn fab dark small color="#25D366">
          <v-icon>mdi-whatsapp</v-icon>
        </v-btn>
      </ShareNetwork>
      <ShareNetwork
        class="text-decoration-none"
        network="facebook"
        :title="title"
        :url="currentUrl"
      >
        <v-btn fab dark small color="#4267B2">
          <v-icon>mdi-facebook</v-icon>
        </v-btn>
      </ShareNetwork>
      <!-- <v-btn :href="instagramUrl" target="_blank" fab dark small color="#E1306C">
        <v-icon>mdi-instagram</v-icon>
      </v-btn> -->
      <ShareNetwork
        class="text-decoration-none"
        network="twitter"
        :title="title"
        :url="currentUrl"
      >
        <v-btn
          fab
          dark
          small
          color="#00acee"
        >
          <v-icon>mdi-twitter</v-icon>
        </v-btn>
      </ShareNetwork>
    </v-speed-dial>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueSocialSharing from "vue-social-sharing";
import Vue from "vue";
Vue.use(VueSocialSharing);
export default {
  components: {},
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    currentUrl: null,
    facebookUrl: null,
    whatsappUrl: null,
    instagramUrl: null,
    twitterUrl: null,
    direction: "top",
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    top: false,
    right: true,
    bottom: true,
    left: false,
    transition: "slide-y-reverse-transition",
    title: null,
  }),
  created() {
    this.title = document.title;
  },
  mounted() {},
  methods: {
    getLink() {
      this.currentUrl = window.location.href;
      this.facebookUrl =
        "https://www.facebook.com/sharer/sharer.php?u=" + this.currentUrl;

      this.twitterUrl =
        "http://twitter.com/share?here&url=" +
        this.currentUrl +
        "&hashtags=hamochi";
      // this.instagramUrl = "https://www.instagram.com/?url=" + this.currentUrl

      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        // true for mobile device
        this.whatsappUrl =
          "https://api.whatsapp.com/send?text=" + this.currentUrl;
      } else {
        // false for not mobile device
        this.whatsappUrl =
          "https://web.whatsapp.com/send?l=en&text=" + this.currentUrl;
      }
    },
  },
};
</script>
<style>
/* This is for documentation purposes and will not be needed in your application */

.create .v-speed-dial {
  position: fixed;
}

.create .v-btn--floating {
  position: relative;
}
</style>
