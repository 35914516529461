export default {
  state: {
    data: false,
  },
  getters: {},
  actions: {
    logout(context) {
      context.commit("updateAuth", false);
    },
  },
  mutations: {
    updateAuth(state, data) {
      if (data == false) {
        var mobile = state.data.Account.mobile;
        data = {
          Account: {
            mobile: mobile,
          },
          Session: false,
        };
      }
      return (state.data = data);
    },
    checkVerifiedMobile(state) {
      if (state.data && state.data.Session && !state.data.Account.isVerifiedMobile) {
        //BOC:[locale]
        var locale = window.location.pathname.replace(/^\/([^/]+).*/i, "$1");
        if (locale == "/") locale = "en";
        //EOC
       // window.location.replace("/" + locale + "/error/restricted");
      }
      return state;
    },
  },
};
