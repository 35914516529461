<template>
  <span>
    <div v-if="skin.body">
      <v-sheet
        :height="height"
        :width="width"
        color="transparent"
        style="position: relative"
      >
        <v-img
          class="avatar"
          :max-height="height"
          :max-width="width"
          :src="avatarShadow"
        ></v-img>
        <v-img
          class="avatar"
          :max-height="height"
          :max-width="width"
          :src="avatarBody"
        ></v-img>
        <v-img
          class="avatar"
          :max-height="height"
          :max-width="width"
          :style="
            'filter : opacity(0.5) drop-shadow(0 0 0 #' + avatarColor + ' )'
          "
          :src="avatarBody"
        ></v-img>
        <v-img
          class="avatar"
          :max-height="height"
          :max-width="width"
          :src="avatarOutline"
        ></v-img>
        <v-img
          class="avatar"
          :max-height="height"
          :max-width="width"
          :src="avatarEye"
        ></v-img>
        <v-img
          class="avatar"
          :max-height="height"
          :max-width="width"
          :src="avatarMouth"
        ></v-img>
        <v-img
          class="avatar"
          :max-height="height"
          :max-width="width"
          :src="avatarHair"
        ></v-img>
        <v-img
          class="avatar"
          :max-height="height"
          :max-width="width"
          :src="avatarCheek"
        ></v-img>
        <v-img
          v-if="avatarHat && !changeHat"
          class="avatar"
          :max-height="height"
          :max-width="width"
          :src="avatarHat"
        ></v-img>
      </v-sheet>
    </div>
  </span>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    avatar: {
      type: String,
    },
    width: {
      type: Number,
      default: 40,
    },
    height: {
      type: Number,
      default: 40,
    },
    changeHat: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Boolean,
      default: false,
    },
  },
  computed: mapState({
    skinData: (state) => state.skin.data,
    hatData: (state) => state.apparel.data,
  }),
  data: () => ({
    renderAvatar: null,
    avatarOutline: null,
    avatarMouth: null,
    avatarBody: null,
    avatarShadow: null,
    avatarEye: null,
    avatarHair: null,
    avatarCheek: null,
    avatarColor: null,
    avatarHat: null,
    mouth: [],
    body: [],
    outline: [],
    shadow: [],
    eye: [],
    hair: [],
    cheek: [],
    hat: [],
    version: null,
    selectedStudent: null,
    student: null,
    code: null,

    skin: [],
    //BOC:[skinApi]
    skinApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[apparelApi]
    apparelApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  created() {
    this.skinApi.method = "get";
    this.skinApi.url =
        this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/library/skin/load";
    this.apparelApi.method = "get";
    this.apparelApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/library/apparel/load";

    this.skinApi.callbackReset = () => {
      this.skinApi.isLoading = true;
      this.skinApi.isError = false;
    };
    this.apparelApi.callbackReset = () => {
      this.apparelApi.isLoading = true;
      this.apparelApi.isError = false;
    };

    this.skinApi.callbackError = (e) => {
      this.skinApi.isLoading = false;
      this.skinApi.isError = true;
      this.skinApi.error = e;
    };
    this.apparelApi.callbackError = (e) => {
      this.apparelApi.isLoading = false;
      this.apparelApi.isError = true;
      this.apparelApi.error = e;
    };

    this.skinApi.callbackSuccess = (resp) => {
      this.skinApi.isLoading = false;
      var skin = resp;
      this.$api.fetch(this.apparelApi);
      skin = this.$_.groupBy(resp, "layer");
      this.$store.commit("updateSkin", skin);
    };
    this.apparelApi.callbackSuccess = (resp) => {
      this.apparelApi.isLoading = false;
      var apparel = resp;
      this.$store.commit("updateApparel", apparel);
      this.skin = this.skinData;
      this.selectSkin();
    };
  },
  mounted() {
    this.renderAvatar = JSON.parse(this.avatar);
    this.version = this.renderAvatar.charKey;
    if (this.version == "mochi_v3") {
      if (!this.skinData) {
        this.$api.fetch(this.skinApi);
      } else {
        this.skin = this.skinData;
        this.selectSkin();
      }
    }
  },
  methods: {
    selectSkin() {
      this.body = this.skin.body;
      this.outline = this.skin.outline;
      this.mouth = this.skin.mouth;
      this.shadow = this.skin.shadow;
      this.eye = this.skin.eye;
      this.hair = this.skin.hair;
      this.cheek = this.skin.cheek;
      var outline = this.renderAvatar.body;
      var mouth = this.renderAvatar.mouth;
      var hat = this.renderAvatar.hat;
      var avatarOutline = this.outline.filter(function (entry) {
        return entry.character == outline;
      });
      this.avatarOutline = JSON.parse(avatarOutline[0].images).F0.url;
      var avatarBody = this.body.filter(function (entry) {
        return entry.character == outline;
      });
      this.avatarBody = JSON.parse(avatarBody[0].images).F0.url;
      var avatarMouth = this.mouth.filter(function (entry) {
        return entry.character == mouth;
      });
      this.avatarMouth = JSON.parse(avatarMouth[0].images).F0.url;

      if (hat) {
        var avatarHat = this.hatData.filter(function (entry) {
          return entry.key == hat;
        });
        this.avatarHat = JSON.parse(avatarHat[0].images).apparel.url;
      }

      this.avatarShadow = JSON.parse(this.shadow[0].images).F0.url;
      this.avatarEye = JSON.parse(this.eye[0].images).F0.url;
      this.avatarHair = JSON.parse(this.hair[0].images).F0.url;
      this.avatarCheek = JSON.parse(this.cheek[0].images).F0.url;
      this.avatarColor = this.renderAvatar.pColor;
    },
  },
};
</script>

<style scoped>
.avatar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>