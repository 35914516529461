<template>
  <div class="d-flex align-center justify-center">
    <div class="form-content">
      <!-- <div class="d-flex align-center justify-center">
        <v-btn
          class="mx-0 px-0"
          plain
          v-if="isShowBack"
          text
          dark
          @click="back()"
          color="primary"
        >
          <v-icon left> mdi-arrow-left </v-icon>
          Back
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="mx-0 px-0"
          plain
          text
          dark
          :to="{ name: 'PageErrorUnderConstruction' }"
          color="primary"
        >
          Get Helps
          <v-icon right> mdi-help </v-icon>
        </v-btn>
      </div> -->
      <v-breadcrumbs class="px-0" :items="items"></v-breadcrumbs>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: {
    isShowBack: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    backLink: null,
    backPath: 0,
  }),
  created() {},
  mounted() {},
  methods: {
    back() {
      this.backPath = this.items.length - 2;
      this.backLink = this.items[this.backPath].to;
      this.$router.push(this.backLink);
    },
  },
};
</script>
<style>
.form-content {
  width: 800px !important;
}
</style>