import { render, staticRenderFns } from "./V3SlideTestimonyParent.vue?vue&type=template&id=3b2cf3ef&"
import script from "./V3SlideTestimonyParent.vue?vue&type=script&lang=js&"
export * from "./V3SlideTestimonyParent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})
