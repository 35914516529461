export default {
  state: {
    data: false,
  },
  getters: {},
  actions: {
    resetSetting(context) {
      context.commit("updateSetting", false);
    },
  },
  mutations: {
    updateSetting(state, data) {
      return (state.data = data);
    },
  },
};
