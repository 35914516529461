<template>
<div class="py-10 text-center">
  <div class="text-h5 mb-3">
    {{ $t("view.PageMainHome.slide_feature_title") }}
  </div>
  <v-row class="text-left">
    <v-col cols="12" :key="n" v-for="n in 3" sm="4">
      <v-card class="grey lighten-4">
        <v-card-title class="text-h4">{{ $t(`view.PageMainHome.slide_feature_${n}_title`) }}</v-card-title>
        <v-card-text class="text-h5" style="min-height:100px;">{{ $t(`view.PageMainHome.slide_feature_${n}_description`) }}</v-card-text>
      </v-card>
    </v-col>
  </v-row>
</div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>