var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","color":"primary","dark":""}},[_c('SiteLogo'),_c('v-spacer'),(_vm.debugMode)?_c('div',{staticClass:"debug-badge"},[_vm._v("Debug Mode")]):_vm._e(),_c('div',{staticClass:"d-flex d-sm-none"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"scroll-x-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"text":"","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('string.menu'))+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('string.menu')))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-list',[_vm._l((_vm.menu),function(item){return [(!item.isGuest || item.isGuest && !(_vm.auth.Session && _vm.auth.Session.token))?_c('div',{key:item.name},[(item.type == 'divider')?[_c('v-divider')]:[_c('v-list-item',{attrs:{"link":"","to":item.route},on:{"click":function($event){_vm.dialog = false}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(("route." + (item.route.name)))))])],1)]],2):_vm._e()]})],2)],1)],1)],1)],1),_c('div',{staticClass:"d-none d-sm-flex"},[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('string.menu'))+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',[_vm._l((_vm.menu),function(item){return [(!item.isGuest || item.isGuest && !(_vm.auth.Session && _vm.auth.Session.token))?_c('div',{key:item.name},[(item.type == 'divider')?[_c('v-divider')]:[_c('v-list-item',{attrs:{"link":"","to":item.route},on:{"click":function($event){_vm.dialog = false}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(("route." + (item.route.name)))))])],1)]],2):_vm._e()]})],2)],1)],1)]),(_vm.auth.Session && _vm.auth.Session.token)?_c('div',{staticClass:"d-flex d-sm-none"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"scroll-x-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"ml-6",attrs:{"color":"white","size":"36"}},'v-avatar',attrs,false),on),[_c('span',{staticClass:"black--text text-h6"},[_vm._v(_vm._s(_vm.auth.User.name.charAt(0).toUpperCase()))])])]}}],null,false,483548229),model:{value:(_vm.dialogAccount),callback:function ($$v) {_vm.dialogAccount=$$v},expression:"dialogAccount"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Account")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialogAccount = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"color":"black","size":"36"}},[_c('span',{staticClass:"white--text text-h6"},[_vm._v(_vm._s(_vm.auth.User.name.charAt(0).toUpperCase()))])])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.auth.User.name))])],1),_c('v-divider'),_c('v-list-item',{attrs:{"to":{ name: 'Page'+_vm.type+'Dashboard' }},on:{"click":function($event){_vm.dialogAccount = false}}},[_c('v-list-item-title',[_vm._v("Dashboard")])],1),_c('v-divider'),_c('v-list-item',{attrs:{"to":{ name: 'PageMainLogout' }}},[_c('v-list-item-title',[_vm._v("Log Out")])],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.auth.Session && _vm.auth.Session.token)?_c('div',{staticClass:"d-none d-sm-flex"},[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"ml-6",attrs:{"color":"white","size":"36"}},'v-avatar',attrs,false),on),[_c('span',{staticClass:"black--text text-h6"},[_vm._v(_vm._s(_vm.auth.User.name.charAt(0).toUpperCase()))])])]}}],null,false,483548229)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"color":"black","size":"36"}},[_c('span',{staticClass:"white--text text-h6"},[_vm._v(_vm._s(_vm.auth.User.name.charAt(0).toUpperCase()))])])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.auth.User.name))])],1),_c('v-divider'),(_vm.type)?_c('v-list-item',{attrs:{"to":{ name: 'Page'+_vm.type+'Dashboard' }},on:{"click":function($event){_vm.dialogAccount = false}}},[_c('v-list-item-title',[_vm._v("Dashboard")])],1):_vm._e(),_c('v-divider'),_c('v-list-item',{attrs:{"to":{ name: 'PageMainLogout' }}},[_c('v-list-item-title',[_vm._v("Log Out")])],1)],1)],1)],1)]):_vm._e()],1),_c('v-main',[_c('router-view',{key:_vm.$route.fullPath})],1),_c('div',{staticClass:"text-right"},[_c('AButtonShare')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }