<template>
  <div class="green accent-1">
    <v-row align="center"
      justify="center">
      <v-col cols="12" sm="6">
        <div class="d-flex flex-column justify-space-between align-center">
          <v-img width="60%" src="/images/Contact/StickerCall.png"></v-img>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="d-flex flex-column justify-space-between align-center" alig>
          <span class="font-weight-bold">
            {{ $t("view.PageMainHome.slide_contact_title") }}
          </span>
          <v-btn
            x-large
            color="#25D366"
            class="ma-2 white--text"
            href="https://api.whatsapp.com/send?phone=60123989864"
            target="_blank"
          >
            <v-img width="40" src="/images/Contact/Whatsapp.svg"></v-img>
            {{ $t("view.PageMainHome.slide_contact_cta") }}
          </v-btn>
           <a href="mailto:info@hamochigames.com" class="text-decoration-none font-weight-regular black--text">
           Email: info@hamochigames.com
           </a>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>