<template>
<div class="py-10 text-center">
  <div class="text-h5 mb-3">
    {{ $t("view.PageMainHome.slide_point_title") }}
  </div>
  <v-card class="text-left grey lighten-5">
    <v-card-text>
      <ul :key="n" v-for="n in 7">
        <li class="text-h6">{{ $t(`view.PageMainHome.slide_point_${n}`) }}</li>
      </ul>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>