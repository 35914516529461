import Vue from 'vue'
import Vuex from 'vuex'
//BOC
import account from "./modules/account";
import apparel from "./modules/apparel";
import auth from './modules/auth'
import group from './modules/group'
import invitationToken from './modules/invitationToken'
import school from './modules/school'
import setting from './modules/setting'
import student from './modules/student'
import skin from "./modules/skin";
//EOC

Vue.use(Vuex)
const debug = process.env.VUE_APP_ENV !== 'production'

//BOC:[persist]
import VuexPersistence from 'vuex-persist'
import Cookies from 'js-cookie'
const vuexCookie = new VuexPersistence({
	key:"cookieStore",
	restoreState: (key) => Cookies.get(key),
	saveState: (key, state) =>
		Cookies.set(key, state, {
			expires: 30, // expire in 30 days
		}),
	modules:[
		//
	],
});
const vuexLocal = new VuexPersistence({
	key:"localStore",
	storage: window.localStorage,
	modules:[
    "account",
    "apparel",
		"auth",
    "group",
    "invitationToken",
		"school",
    "setting",
    "student",
    "skin",
	],
})
//EOC

export default new Vuex.Store({
	modules: {
    account:account,
    apparel:apparel,
		auth:auth,
    group:group,
    invitationToken:invitationToken,
		school:school,
    setting:setting,
    student:student,
    skin:skin,
	},
	strict: debug,
	plugins: [
		vuexCookie.plugin, 
		vuexLocal.plugin,
	],
})
