<template>
  <v-sheet color="lighten-4" class="pa-3">
    <v-skeleton-loader
      class="mx-auto"
      :max-width="width"
      :type="type"
    ></v-skeleton-loader>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    
  },
  computed: mapState({
    //
  }),
  props: [
    "type","width",
  ],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>