import Vue from 'vue'

Vue.mixin({
	methods: {
		$_hasRoute(route) {
			var resolved = this.$router.resolve(route)
      return !(resolved.href == '/'+this.$_getLocale()+'/') 
		},
		$_getRouteBrowse(role, key) {
			var route = {name:'Page'+role+key+'Browse'}
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataBrowse',params:{object:this.$_lcfirst(key)}}
			return route
		},
		$_getRouteRead(role, key, id, self=false) {
			var route = {name:'Page'+role+key+'Read',params:{id:id,self:self}}
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataRead',params:{object:this.$_lcfirst(key),id:id,self:self}}
			return route
		},
		$_getRouteAdd(role, key) {
			var route = {name:'Page'+role+key+'Add'}
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataAdd',params:{object:this.$_lcfirst(key)}}
			return route
		},
		$_getRouteEdit(role, key, id, self=false) {
			var route = {name:'Page'+role+key+'Edit',params:{id:id,self:self}}
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataEdit',params:{object:this.$_lcfirst(key),id:id,self:self}}
			return route
		},
		$_getRouteDelete(role, key, id, self=false) {
			var route = {name:'Page'+role+key+'Delete',params:{id:id,self:self}}
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataDelete',params:{object:this.$_lcfirst(key),id:id,self:self}}
			return route
		},
    $_getRouteDeleteHard(role, key, id, self=false) {
			var route = {name:'Page'+role+key+'DeleteHard',params:{id:id,self:self}}
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataDeleteHard',params:{object:this.$_lcfirst(key),id:id,self:self}}
			return route
		},
    $_getRouteRestore(role, key, id, self=false) {
			var route = {name:'Page'+role+key+'Restore',params:{id:id,self:self}}
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataRestore',params:{object:this.$_lcfirst(key),id:id,self:self}}
			return route
		},
    $_getRouteChildBrowse(role, key,grandParentId,grandParent=false, parentKey) {
			var route = {name:'Page'+role+parentKey+key+'Browse',params:{id:grandParentId,grandParent:grandParent}}
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataBrowse',params:{object:this.$_lcfirst(key)}}
			return route
		},
    $_getRouteChildRead(role, key, id, self=false, parentKey, parentId, parent=false) {
			var route = {name:'Page'+role+parentKey+key+'Read',params:{id:id,self:self,parentId:parentId,parent:parent}}
      console.log(route)
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataChildRead',params:{object:this.$_lcfirst(key),id:id,self:self,parentId:parentId,parent:parent}}
			return route
		},
		$_getRouteChildEdit(role, key, id, self=false, parentKey, parentId, parent=false) {
			var route = {name:'Page'+role+parentKey+key+'Edit',params:{id:id,self:self,parentId:parentId,parent:parent}}
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataChildEdit',params:{object:this.$_lcfirst(key),id:id,self:self,parentId:parentId,parent:parent}}
			return route
		},
		$_getRouteChildDelete(role, key, id, self=false, parentKey, parentId, parent=false) {
			var route = {name:'Page'+role+parentKey+key+'Delete',params:{id:id,self:self,parentId:parentId,parent:parent}}
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataChildDelete',params:{object:this.$_lcfirst(key),id:id,self:self,parentId:parentId,parent:parent}}
			return route
		},
    $_getRouteChildDeleteHard(role, key, id, self=false, parentKey, parentId, parent=false) {
			var route = {name:'Page'+role+parentKey+key+'DeleteHard',params:{id:id,self:self,parentId:parentId,parent:parent}}
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataChildDeleteHard',params:{object:this.$_lcfirst(key),id:id,self:self,parentId:parentId,parent:parent}}
			return route
		},
    $_getRouteChildRestore(role, key, id, self=false, parentKey, parentId, parent=false) {
			var route = {name:'Page'+role+parentKey+key+'Restore',params:{id:id,self:self,parentId:parentId,parent:parent}}
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataChildRestore',params:{object:this.$_lcfirst(key),id:id,self:self,parentId:parentId,parent:parent}}
			return route
		},
    $_getRouteGrandChildRead(role, key, id, self=false, parentKey, parentId, parent=false,grandParentKey,grandParentId,grandParent= false) {
			var route = {name:'Page'+role+grandParentKey+parentKey+key+'Read',params:{id:id,self:self,parentId:parentId,parent:parent,grandParentId:grandParentId,grandParent:grandParent}}
      console.log('hiii',route)
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataChildRead',params:{object:this.$_lcfirst(key),id:id,self:self,parentId:parentId,parent:parent,grandParentId:grandParentId,grandParent:grandParent}}
			return route
		},
    $_getRouteGrandChildDelete(role, key, id, self=false, parentKey, parentId, parent=false,grandParentKey,grandParentId,grandParent= false) {
			var route = {name:'Page'+role+grandParentKey+parentKey+key+'Delete',params:{id:id,self:self,parentId:parentId,parent:parent,grandParentId:grandParentId,grandParent:grandParent}}
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataChildDelete',params:{object:this.$_lcfirst(key),id:id,self:self,parentId:parentId,parent:parent,grandParentId:grandParentId,grandParent:grandParent}}
			return route
		},
    $_getRouteGrandChildDeleteHard(role, key, id, self=false, parentKey, parentId, parent=false,grandParentKey,grandParentId,grandParent= false) {
			var route = {name:'Page'+role+grandParentKey+parentKey+key+'DeleteHard',params:{id:id,self:self,parentId:parentId,parent:parent,grandParentId:grandParentId,grandParent:grandParent}}
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataChildDeleteHard',params:{object:this.$_lcfirst(key),id:id,self:self,parentId:parentId,parent:parent,grandParentId:grandParentId,grandParent:grandParent}}
			return route
		},
    $_getRouteGrandChildEdit(role, key, id, self=false, parentKey, parentId, parent=false,grandParentKey,grandParentId,grandParent= false) {
			var route = {name:'Page'+role+grandParentKey+parentKey+key+'Edit',params:{id:id,self:self,parentId:parentId,parent:parent,grandParentId:grandParentId,grandParent:grandParent}}
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataChildEdit',params:{object:this.$_lcfirst(key),id:id,self:self,parentId:parentId,parent:parent,grandParentId:grandParentId,grandParent:grandParent}}
			return route
		},
    $_getRouteGrandChildRestore(role, key, id, self=false, parentKey, parentId, parent=false,grandParentKey,grandParentId,grandParent= false) {
			var route = {name:'Page'+role+grandParentKey+parentKey+key+'Restore',params:{id:id,self:self,parentId:parentId,parent:parent,grandParentId:grandParentId,grandParent:grandParent}}
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataChildRestore',params:{object:this.$_lcfirst(key),id:id,self:self,parentId:parentId,parent:parent,grandParentId:grandParentId,grandParent:grandParent}}
			return route
		},
      
    $_getRouteGreatGrandChildRead(role, key, id, self=false, parentKey, parentId, parent=false,grandParentKey,grandParentId,grandParent= false,greatGrandParentKey,greatGrandParentId,greatGrandParent= false) {
			var route = {name:'Page'+role+greatGrandParentKey+grandParentKey+parentKey+key+'Read',params:{id:id,self:self,parentId:parentId,parent:parent,grandParentId:grandParentId,grandParent:grandParent,greatGrandParentId:greatGrandParentId,greatGrandParent:greatGrandParent}}
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataChildRead',params:{object:this.$_lcfirst(key),id:id,self:self,parentId:parentId,parent:parent,grandParentId:grandParentId,grandParent:grandParent}}
      return route
		},

    $_getRouteGreatGrandChildEdit(role, key, id, self=false, parentKey, parentId, parent=false,grandParentKey,grandParentId,grandParent= false,greatGrandParentKey,greatGrandParentId,greatGrandParent= false) {
			var route = {name:'Page'+role+greatGrandParentKey+grandParentKey+parentKey+key+'Edit',params:{id:id,self:self,parentId:parentId,parent:parent,grandParentId:grandParentId,grandParent:grandParent,greatGrandParentId:greatGrandParentId,greatGrandParent:greatGrandParent}}
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataChildEdit',params:{object:this.$_lcfirst(key),id:id,self:self,parentId:parentId,parent:parent,grandParentId:grandParentId,grandParent:grandParent}}
			return route
		},

    $_getRouteGreatGrandChildDelete(role, key, id, self=false, parentKey, parentId, parent=false,grandParentKey,grandParentId,grandParent= false,greatGrandParentKey,greatGrandParentId,greatGrandParent= false) {
			var route = {name:'Page'+role+greatGrandParentKey+grandParentKey+parentKey+key+'Delete',params:{id:id,self:self,parentId:parentId,parent:parent,grandParentId:grandParentId,grandParent:grandParent,greatGrandParentId:greatGrandParentId,greatGrandParent:greatGrandParent}}
			if(!this.$_hasRoute(route)) route = {name:'Page'+role+'DataChildDelete',params:{object:this.$_lcfirst(key),id:id,self:self,parentId:parentId,parent:parent,grandParentId:grandParentId,grandParent:grandParent}}
			return route
		},

	}
})