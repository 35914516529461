<template>
  <div class="pt-5 mx-0 text-center grey lighten-4">
    <div class="text-h5">
      {{ $t("view.PageMainHome.slide_testimony_title") }}
    </div>
    <v-carousel
      height="auto"
      class="py-1"
      hide-delimiter-background
      show-arrows-on-hover
    >
      <v-carousel-item v-for="(testimony, i) in testimonies" :key="i">
        <v-sheet class="my-3" color="grey lighten-4" height="100%" min-height="250">
          <v-row class="fill-height pa-3" align="center" justify="center">
            <v-col cols="12" md="2">
              <v-avatar size="110">
                <img alt="user" :src="testimony.photo" />
              </v-avatar>
            </v-col>
            <v-col cols="12" md="10">
              <p class="text-left" v-html="$t(testimony.message)">
              </p>
              <p class="font-weight-bold text-left">{{ $t(testimony.name) }}</p>
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    //
    colors: [
      "indigo",
      "warning",
      "pink darken-2",
      "red lighten-1",
      "deep-purple accent-4",
    ],
    slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    testimonies: [
      {
        id: 1,
        photo: "/images/Testimony/PZ.jpeg",
        message: "view.PageMainHome.slide_testimony_1_message",
        name: "view.PageMainHome.slide_testimony_1_name",
      },
      {
        id: 4,
        photo: "/images/Testimony/FYL.jpeg",
        message: "view.PageMainHome.slide_testimony_4_message",
        name: "view.PageMainHome.slide_testimony_4_name",
      },
      {
        id: 3,
        photo: "/images/Testimony/HEL.jpeg",
        message: "view.PageMainHome.slide_testimony_3_message",
        name: "view.PageMainHome.slide_testimony_3_name",
      },
      // {
      //   id:2,
      //   photo:'/images/Testimony/SJ.jpeg',
      //   message:'view.PageMainHome.slide_testimony_2_message',
      //   name:'view.PageMainHome.slide_testimony_2_name',
      // },
    ],
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>