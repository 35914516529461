<template>
<div>
  <v-row align="center"
      justify="center">
   <div width="100px" class="pa-6 text-caption">Copyright © {{year}} Hamochi Sdn Bhd. All Rights Reserved.</div>
  </v-row>
</div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      year:null,
    }),
    created() {
      this.year = this.$moment().format('YYYY')
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>