<template>
<v-btn
  href="/"
  text
  exact
>
  <div class="d-flex align-center">
    <!--
    <v-img
      alt="Vuetify Logo"
      class="shrink mr-2 d-none d-sm-flex"
      contain
      :src="require('@/assets/Icon.png')"
      transition="scale-transition"
      width="40"
    ></v-img>
    -->
    <span class="mr-2">Hamochi</span>
    <!--
    <span class="mr-2 text-caption" style="font-size:9px !important; text-transform:none;">( Hamster x Mochi )</span>
    -->
    <v-chip
      small
      class="mr-1 d-none d-sm-flex"
    >Beta {{$version}}</v-chip>
    <v-chip
      v-if="env != 'production' && env != 'release'"
      small
      class="d-none d-sm-flex"
    >{{env}}</v-chip>
  </div>
</v-btn>
</template>

<script>
  export default {
    data: () => ({
      env:process.env.VUE_APP_ENV,
    }),
  }
</script>
