<template>
<div>
  <v-row>
    <v-col class="py-0 mt-2">
      <v-card class="pink lighten-3 pa-2">
        <div class="d-flex flex-no-wrap justify-space-between align-center">
          <v-img :max-width="200" contain src="/images/20230507_teacher.png"></v-img>
          <div class="text-right">
            <v-card-title class="py-0">{{$t('view.PageMainLogin.teacher_title')}}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large color="pink darken-2" dark rounded class="mx-2"
                :to="(auth && auth.Session) ? {name:`Page${$_ucfirst(auth.Account.type)}Dashboard`} : {name:'PageMainLogin'}"
              >{{$t('action.login')}}</v-btn>
            </v-card-actions>
          </div>
        </div>
      </v-card>
    </v-col>
    <v-col class="py-0 mt-2">
      <v-card class="yellow lighten-2 pa-2">
        <div class="d-flex flex-no-wrap justify-space-between align-center">
          <div class="text-right">
            <v-card-title class="py-0">{{$t('view.PageMainLogin.student_title')}}</v-card-title>
            <v-card-actions>
              <v-btn large color="yellow darken-2" rounded class="mx-2"
                :href="gameUrl"
                target="_blank"
              >{{$t('action.login')}}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </div>
          <v-img :max-width="200" contain src="/images/20230507_student.png"></v-img>
        </div>
      </v-card>
    </v-col>
  </v-row>
</div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      auth: (state) => state.auth.data,
    }),
    props:[
      //
    ],
    data: () => ({
      roles:[
        {
          image:"/images/LoginTeacher.png"
        },
        {
          image:"/images/LoginStudent.png"
        },
      ],
      gameUrl:process.env.VUE_APP_URL_WEB_GAME,
    }),
    created() {
      this.gameUrl += `/${this.$_getLocale()}/access`
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  };
</script>