<template>
<div class="pt-5 py-10 mx-0 text-center">
  <div class="text-h5 mb-5">{{ $t("view.PageMainHome.slide_client_title") }}</div>
  <v-row>
    <v-col cols="12" sm="4" :key="client.name" v-for="client in clients.slice(0,min)">
      <div class="pb-1" style="border-bottom:black solid 2px;">{{client.name}}, {{client.state}}</div>
    </v-col>
    <template v-if="shownMore">
      <v-col cols="12" sm="4" :key="client.name" v-for="client in clients.slice(min,clients.length)">
        <div class="pb-1" style="border-bottom:black solid 2px;">{{client.name}}, {{client.state}}</div>
      </v-col>
    </template>
    <v-col v-if="!shownMore && clients.length > 6">
      <v-btn 
        small 
        color="primary" 
        text
        @click="showMore"
      >{{ $t("view.PageMainHome.slide_client_button_more") }}</v-btn>
    </v-col>
  </v-row>
</div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      shownMore:false,
      min:5,
      clients:[
        {
          name:'SJK(C) Ayer Tawar',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Bukit Pagar',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Chenderiang',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Chinese National',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Chung Sin',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Hwa Lian 1',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Hwa Lian 2',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Kampar Girls',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Kampung Baharu Batu Dua',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Kampung Merbau',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Kur Seng',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Min Te',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Pekan Gurney',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Phui Choi',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Ping Min',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Sey Wah',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Siu Sin',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Sam Tet',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Uk Dih',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Uk Ing',
          state:'Perak',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Chee Mong',
          state:'Terengganu',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Chong Hwa',
          state:'Terengganu',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Chukai',
          state:'Terengganu',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Chung Hwa Wei Sin',
          state:'Terengganu',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Jabor',
          state:'Terengganu',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Kuala Kemaman',
          state:'Terengganu',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Kwang Hwa',
          state:'Terengganu',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Lok Khoon',
          state:'Terengganu',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Sin Chone',
          state:'Terengganu',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Choong Chee',
          state:'Selangor',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Kampung Gurney',
          state:'Selangor',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Rasa',
          state:'Selangor',
          country:'Malaysia',
        },
        {
          name:'SJK(C) Serendah',
          state:'Selangor',
          country:'Malaysia',
        },
      ],
    }),
    created() {
      //this.clients.reverse()
    },
    mounted() {
      //
    },
    methods: {
      showMore() {
        this.shownMore = true
      },
    }
  }
</script>