<template>
  <span>
    <AAvatarV2
      v-if="version == 'mochi_v2'"
      :height="height"
      :width="width"
      :avatar="avatar"
    />
    <AAvatarV3
      v-else-if="version == 'mochi_v3'"
      :height="height"
      :width="width"
      :avatar="avatar"
    />
    <AAvatarV4
      v-else-if="version == 'mochi_v4'"
      :height="height"
      :width="width"
      :avatar="avatar"
      :isShowShadow="isShowShadow"
    />
  </span>
</template>

<script>
import { mapState } from "vuex";
import AAvatarV3 from "./AAvatarV3.vue";
import AAvatarV2 from "./AAvatarV2.vue";
import AAvatarV4 from "./AAvatarV4.vue";
export default {
  props: {
    avatar: {
      type: String,
    },
    width: {
      type: Number,
      default: 40,
    },
    height: {
      type: Number,
      default: 40,
    },
    changeHat: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Boolean,
      default: false,
    },
    isShowShadow: {
      type: Boolean,
      default: true,
    },
  },
  computed: mapState({
    skinData: (state) => state.skin.data,
    hatData: (state) => state.apparel.data,
  }),
  data: () => ({
    version: null,
  }),
  mounted() {
    this.renderAvatar = JSON.parse(this.avatar);
    this.version = this.renderAvatar.charKey;
  },
  components: { AAvatarV3, AAvatarV2, AAvatarV4 },
};
</script>

<style scoped>
</style>