<template>
<div class="py-10 text-center">
  <div class="text-h5 mb-3">
    {{ $t("view.PageMainHome.slide_performance_title") }}
  </div>
  <div class="mb-3">
    ({{ $t("view.PageMainHome.slide_performance_description") }})
  </div>
  <v-sheet class="text-left green lighten-5 pa-3">
      <ul :key="n" v-for="n in 7">
        <li class="text-h6">{{ $t(`view.PageMainHome.slide_performance_${n}`) }}</li>
      </ul>
  </v-sheet>
</div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>