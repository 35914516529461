<template>
  <div>
   
<router-view/>
  </div>

</template>

<script>
import { mapState } from "vuex";
export default {
	name: 'App',
	data: () => ({
		debugMode: false
	}),
  computed: {
    ...mapState({
      setting: (state) => state.setting.data,
    }),
  },
  mounted(){
   // var setting = this.$_.cloneDeep(this.setting);
    // if(this.$route.query.debug){
    //   this.$store.commit("updateSetting", {debug : true});
    //   this.debugMode = true
    // }else {
    //   this.$store.commit("updateSetting", {debug : false});
    //   this.debugMode = false
    // }
  }
};
</script>
<style scoped>
.debug-badge {
  position: fixed;
  top: 0;
  left: 0;
  background-color: red;
  color: white;
  padding: 5px;
  font-size: 12px;
  z-index: 9999;
}
</style>
