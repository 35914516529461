<template>
  <div class="py-5 mx-0 ">
    <div class="text-h5 text-center">
      {{ $t("view.PageMainHome.slide_faq_title") }}
    </div>
    <v-list>
      <v-list-group
        v-for="item in faqs.slice(0, minFaq)"
        :key="item.title"
        v-model="item.active"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="primary--text" v-text="$t(item.question)"></v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item  class="text-left blue-grey lighten-4">
          <v-list-item-content >
            <p >{{$t(item.answer)}}</p>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <div class="text-right" v-if="!shownMore">
        <v-btn small color="primary" text @click="showMore">{{
          $t("view.PageMainHome.slide_client_button_more")
        }}</v-btn>
      </div>

      <span v-if="shownMore">
        <v-list-group
          v-for="item in faqs.slice(minFaq, faqs.length)"
          :key="item.title"
          v-model="item.active"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="primary--text" v-text="$t(item.question)"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item class="text-left blue-grey lighten-4">
            <v-list-item-content >
                <p>{{$t(item.answer)}}</p>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </span>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    //
    refresh: true,
    shownMore: false,
    shown: [],
    minFaq: 2,
    faqs: [],
    items: [
      {
        action: "mdi-tag",
        items: [{ title: "List Item" }],
        title: "Promotions",
      },
    ],
  }),
  created() {
    //
    this.faqs = [
      {
        question: "view.PageMainHome.slide_faq_1_question",
        answer: "view.PageMainHome.slide_faq_1_answer",
      },
      {
        question: "view.PageMainHome.slide_faq_2_question",
        answer: "view.PageMainHome.slide_faq_2_answer",
      },
      {
        question: "view.PageMainHome.slide_faq_3_question",
        answer: "view.PageMainHome.slide_faq_3_answer",
      },
      {
        question: "view.PageMainHome.slide_faq_4_question",
        answer: "view.PageMainHome.slide_faq_4_answer",
      },
      {
        question: "view.PageMainHome.slide_faq_5_question",
        answer: "view.PageMainHome.slide_faq_5_answer",
      },
      // {
      //   question: "view.PageMainHome.slide_faq_6_question",
      //   answer: "view.PageMainHome.slide_faq_6_answer",
      // },
      {
        question: "view.PageMainHome.slide_faq_7_question",
        answer: "view.PageMainHome.slide_faq_7_answer",
      },
      {
        question: "view.PageMainHome.slide_faq_8_question",
        answer: "view.PageMainHome.slide_faq_8_answer",
      },
      {
        question: "view.PageMainHome.slide_faq_9_question",
        answer: "view.PageMainHome.slide_faq_9_answer",
      },
    ];
  },
  mounted() {
    //
  },
  methods: {
    //
    showMore() {
      this.shownMore = true;
    },
  },
};
</script>