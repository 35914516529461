<template>
  <div>
    <div v-if="api.isLoading" class="text-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-row  v-else>
      <v-col v-for="question in data" :key="question.key" cols="12" sm="4">
        <v-card height="100%">
          <v-card-title>{{ question.name }}</v-card-title>
          <div v-if="question.question_code">
            <v-divider></v-divider>
            <v-chip x-small class="ma-1" style="float: right"
              >{{$t('string.sample_question')}}</v-chip
            >
            <div class="pa-3" style="min-height: 180px">
              <div
                style="
                  -webkit-transform: scale(0.8);
                  -moz-transform: scale(0.8);
                  -ms-transform: scale(0.8);
                  transform: scale(0.8);
                "
                v-html="question.question_code.question"
              ></div>
            </div>
            <v-divider></v-divider>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              :to="{ name: 'PageMainDiscoverQuiz', params: { code: question.key } }"
            >
              {{$t('action.testPlay')}}
            </v-btn>
          <v-btn
              color="primary"
              text
              :to="{ name: 'PageMainDiscoverSample', params: {chapterkey: chapter.key, code: question.key } }"
            >
              {{$t('action.view')}}
            </v-btn>
          
           
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  props: ["callbackError", "chapter"],
  data: () => ({
    dialogs: {
      view: false,
    },
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    key: null,
    item: null,
  }),
  components: {
   
  },
  created() {
    //BOC:[api]
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
    };
    //EOC
  },
  watch: {
    //
  },
  methods: {
    fetch() {
      this.api.url =
        this.$api.servers.question +
        "/v2/chapters/" +
        this.chapter.key +
        "/questions?lang=" +
        this.$_getLocale();
      this.$api.fetch(this.api);
    },
  },
};
</script>
<style>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
