<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <SiteLogo></SiteLogo>
      <v-spacer></v-spacer>
      <div v-if="debugMode" class="debug-badge">Debug Mode</div>
      <div class="d-flex d-sm-none">
        <v-row justify="center">
          <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="scroll-x-reverse-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn text dark class="mr-4" v-bind="attrs" v-on="on">
                {{$t('string.menu')}}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-toolbar dark color="primary">
                <v-toolbar-title>{{$t('string.menu')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-list>
                <template v-for="item in menu">
                  <div :key="item.name" v-if="!item.isGuest || item.isGuest && !(auth.Session && auth.Session.token)">
                    <template v-if="item.type == 'divider'">
                      <v-divider></v-divider>
                    </template>
                    <template v-else>
                      <v-list-item
                        link
                        :to="item.route"
                        @click="dialog = false"
                      >
                        <v-list-item-title>{{ $t(`route.${item.route.name}`) }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </div>
                </template>
              </v-list>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <div class="d-none d-sm-flex">
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text dark v-bind="attrs" v-on="on">
                {{$t('string.menu')}}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <template v-for="item in menu">
                <div :key="item.name" v-if="!item.isGuest || item.isGuest && !(auth.Session && auth.Session.token)">
                  <template v-if="item.type == 'divider'">
                    <v-divider></v-divider>
                  </template>
                  <template v-else>
                    <v-list-item
                      link
                      :to="item.route"
                      @click="dialog = false"
                    >
                      <v-list-item-title>{{ $t(`route.${item.route.name}`) }}</v-list-item-title>
                    </v-list-item>
                  </template>
                </div>
              </template>
            </v-list>
          </v-menu>
        </div>
      </div>

      <div v-if="auth.Session && auth.Session.token" class="d-flex d-sm-none">
        <v-row justify="center">
          <v-dialog
            v-model="dialogAccount"
            fullscreen
            hide-overlay
            transition="scroll-x-reverse-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-bind="attrs"
                class="ml-6"
                v-on="on"
                color="white"
                size="36"
              >
                <span class="black--text text-h6">{{
                  auth.User.name.charAt(0).toUpperCase()
                }}</span></v-avatar
              >
            </template>
            <v-card>
              <v-toolbar dark color="primary">
                <v-toolbar-title>Account</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn icon dark @click="dialogAccount = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-avatar color="black" size="36">
                      <span class="white--text text-h6">{{
                        auth.User.name.charAt(0).toUpperCase()
                      }}</span></v-avatar
                    >
                  </v-list-item-avatar>
                  <v-list-item-title>{{ auth.User.name }}</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item
                :to="{ name: 'Page'+type+'Dashboard' }"
                  @click="dialogAccount = false"
                >
                  <v-list-item-title>Dashboard</v-list-item-title>
                </v-list-item>
                <!-- <v-list-item
                :to="{ name: 'Page'+type+'MyAccount' }"
                  @click="dialogAccount = false"
                >
                  <v-list-item-title>My Account</v-list-item-title>
                </v-list-item> -->
                <!-- <v-list-item >
                  <v-list-item-title>My Plan</v-list-item-title>
                </v-list-item> -->
                <v-divider></v-divider>
                <v-list-item :to="{ name: 'PageMainLogout' }">
                  <v-list-item-title>Log Out</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <div v-if="auth.Session && auth.Session.token" class="d-none d-sm-flex">
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-bind="attrs"
                class="ml-6"
                v-on="on"
                color="white"
                size="36"
              >
                <span class="black--text text-h6">{{
                  auth.User.name.charAt(0).toUpperCase()
                }}</span></v-avatar
              >
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-avatar color="black" size="36">
                    <span class="white--text text-h6">{{
                      auth.User.name.charAt(0).toUpperCase()
                    }}</span></v-avatar
                  >
                </v-list-item-avatar>
                <v-list-item-title>{{ auth.User.name }}</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                v-if="type"
                :to="{ name: 'Page'+type+'Dashboard' }"
                  @click="dialogAccount = false"
                >
                  <v-list-item-title>Dashboard</v-list-item-title>
                </v-list-item>
              <!-- <v-list-item  :to="{ name: 'PageAccountMyAccount' }"
                  @click="dialogAccount = false">
                <v-list-item-title>My Account</v-list-item-title>
              </v-list-item> -->
              <!-- <v-list-item>
                <v-list-item-title>My Plan</v-list-item-title>
              </v-list-item> -->
              <v-divider></v-divider>
              <v-list-item :to="{ name: 'PageMainLogout' }">
                <v-list-item-title>Log Out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-app-bar>
    <v-main>
      <router-view :key="$route.fullPath" />
    </v-main>
    <div class="text-right">
      <AButtonShare></AButtonShare>
    </div>
  </v-app>
</template>

<script>
import SiteLogo from "./../components/SiteLogo";
import { mapState } from "vuex";
export default {
  computed: mapState({
    auth: (state) => state.auth.data,
    setting: (state) => state.setting.data,
  }),
  components: {
    SiteLogo,
  },
  data: () => ({
    showMainMenu: false,
    type: null,
    showAccountMenu: false,
    minimised: false,
    dialog: false,
    dialogAccount: false,
    notifications: false,
    sound: true,
    widgets: false,
    debugMode: false,
    menu:[
      {
        icon: "mdi-home",
        route: { name: "PageMainHome" },
      },
      {
        icon: "mdi-calendar-star",
        route: { name: "PageMainEvent" },
      },
      {
        icon: "mdi-cloud-question",
        route: { name: "PageMainDiscover" },
      },
      // {
      //   icon: "mdi-bullhorn",
      //   route: { name: "PageMainNews" },
      // },
      {
        type: "divider",
      },
      {
        icon: "mdi-earth",
        route: { name: "PageMainLogin" },
        isGuest: true,
      },
      {
        type: "divider",
      },
      {
        icon: "mdi-earth",
        route: { name: "PageMainLanguage" },
      },
      // {
      //   icon: "mdi-help",
      //   route: { name: "PageMainHelp" },
      // },
      // {
      //   type: "divider",
      // },
      // {
      //   icon: "mdi-currency-usd",
      //   route: { name: "PageMainSupportUs" },
      // },
    ],
  }),
  created() {
    //BOC:[type]
    if(this.auth && this.auth.Account.type){
      this.type = this.$_ucfirst(this.auth.Account.type);
    }
    //EOC
  },
  mounted(){
    if(this.$route.query.debug && this.$route.query.debug == '1'){
      this.$store.commit("updateSetting", {debug : true});
    }else if(this.$route.query.debug && this.$route.query.debug != '1') {
      this.$store.commit("updateSetting", {debug : false});
    }
    
    this.debugMode = this.setting.debug
    this.$store.commit("checkVerifiedMobile");
this.setMaintenanceEndTime()
  },
  methods: {
    clickAccountMenu() {
      this.showMainMenu = false;
      this.showAccountMenu = !this.showAccountMenu;
    },
    clickMainMenu() {
      this.showAccountMenu = false;
      this.showMainMenu = !this.showMainMenu;
    },
    setMaintenanceEndTime() {
      const currentTime = this.$moment();
      const maintenanceStart = this.$moment().set({'hour':1,'minute':0,'second':0,'millisecond':0});
      const maintenanceEnd = this.$moment().set({'hour':23,'minute':0,'second':0,'millisecond':0});

      if (currentTime.isBetween(maintenanceStart, maintenanceEnd) || this.setting.debug) {
        return true
      } else{
        this.$router.push({name:'PageErrorUnderMaintenance'})
      }
    },
   
  },
};
</script>
<style scoped>
.debug-badge {
  position: fixed;
  top: 0;
  left: 0;
  background-color: red;
  color: white;
  padding: 5px;
  font-size: 12px;
  z-index: 9999;
}
</style>