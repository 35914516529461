<template>
  <v-container>
    <SlideLogin />
    <SlidePitch />
    <SlideVideo />
    <SlideFeature />
    <SlidePoint v-if="this.$_getLocale() == 'zh'" />
    <SlideEvent />
    <!-- <SlideGame /> -->
    <SlidePerformance v-if="this.$_getLocale() == 'zh'" />
    <SlideClient />
    <SlideTestimony />
    <SlideTestimonyParent v-if="this.$_getLocale() == 'zh'" />
    <SlideFAQ />
    <SlideSyllabus />
    <SlideContact/>
    <!-- <SlideSocial/> -->
    <SlideCopyright/>
  </v-container>
</template>

<script>
import SlideClient from "@/components/views/PageMainHome/V3SlideClient.vue";
import SlideContact from "@/components/views/PageMainHome/SlideContact.vue";
import SlideCopyright from "@/components/views/PageMainHome/V3SlideCopyright.vue";
import SlideEvent from "@/components/views/PageMainHome/V3SlideEvent.vue";
import SlideFAQ from "@/components/views/PageMainHome/V3SlideFAQ.vue";
import SlideFeature from "@/components/views/PageMainHome/V3SlideFeature.vue";
//import SlideGame from "@/components/views/PageMainHome/SlideGame.vue";
import SlideLogin from "@/components/views/PageMainHome/V3SlideLogin.vue";
import SlidePerformance from "@/components/views/PageMainHome/V3SlidePerformance.vue";
import SlidePitch from "@/components/views/PageMainHome/V3SlidePitch.vue";
import SlidePoint from "@/components/views/PageMainHome/V3SlidePoint.vue";
//import SlideSocial from "@/components/views/PageMainHome/SlideSocial.vue";
import SlideSyllabus from "@/components/views/PageMainHome/SlideSyllabus.vue";
import SlideTestimony from "@/components/views/PageMainHome/SlideTestimony.vue";
import SlideTestimonyParent from "@/components/views/PageMainHome/V3SlideTestimonyParent.vue";
import SlideVideo from "@/components/views/PageMainHome/V3SlideVideo.vue";
export default {
  components: {
    SlideClient,
    SlideContact,
    SlideCopyright,
    SlideEvent,
    SlideFAQ,
    SlideFeature,
    //SlideGame,
    SlideLogin,
    SlidePerformance,
    SlidePitch,
    SlidePoint,
    //SlideSocial,
    SlideSyllabus,
    SlideTestimony,
    SlideTestimonyParent,
    SlideVideo,
  },
  data: () => ({
    //
  }),
};
</script>
