<template>
<div class="py-10">
  <v-row>
    <v-col sm="7">
      <v-img
        alt="Teaser"
        contain
        :src="require('@/assets/PageMainHome/Pitch2024.png')"
        transition="fade-transition"
      ></v-img>
    </v-col>
    <v-col sm="5" class="d-flex align-center justify-center">
      <v-card color="blue lighten-5" class="text-center">
        <v-card-text>
          <div class="text-h3 mb-3 black--text">
            {{ $t("view.PageMainHome.slide_v3_pitch_title") }}
          </div>
          <div class="text-h5 mb-3" v-html="$t('view.PageMainHome.slide_v3_pitch_description_1')"></div>
          <div class="text-h5 mb-3" v-html="$t('view.PageMainHome.slide_v3_pitch_description_2')"></div>
          <div class="text-h5 mb-3" v-html="$t('view.PageMainHome.slide_v3_pitch_description_3')"></div>
          <div class="text-h6 mb-3">Email: <a href="mailto:info@hamochigames.com">info@hamochigames.com</a></div>
          <!-- <div>
            <v-btn 
              block
              x-large 
              class="text-h5 mb-3"
              color="primary"
              :to="{name:'PageMainApply'}"
            >{{ $t("view.PageMainHome.slide_v3_pitch_button") }}</v-btn>
          </div> -->
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</div>
</template>

<style scoped>
.heart {
  position:absolute;
  left:0;
  right:0;
  margin:0 auto;
 }

 @keyframes heartFadeInOut {
  0% {transform: scale(1);}
  25% {transform: scale(.97);}
  35% {transform: scale(.9);}
  45% {transform: scale(1.1);}
  55% {transform: scale(.9);}
  65% {transform: scale(1.1);}
  75% {transform: scale(1.03);}
  100% {transform: scale(1);}
}

.heart { 
  animation-name: heartFadeInOut; 
  animation-iteration-count: infinite;
  animation-duration: 2s;
}
</style>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>