<template>
<v-container>
  <!-- BOC:[breadcrumbs] -->
  <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
  <!-- EOC -->
  <transition name="fade">
    <v-alert
      v-if="api.isError"
      dense
      border="left"
      type="warning"
    >
      {{api.error}}
    </v-alert>
  </transition>
  <v-row>
    <v-col>
      <DropdownSyllabus
        ref="dropdown_syllabus"
        :callbackSelect="selectSyllabus"
        :callbackError="showError"
      ></DropdownSyllabus>
    </v-col>
    <v-col>
      <DropdownChapter
        ref="dropdown_chapter"
        :syllabus="syllabus"
        :callbackSelect="selectChapter"
        :callbackError="showError"
      ></DropdownChapter>
    </v-col>
  </v-row>
  <v-divider class="my-3"></v-divider>
  <div v-if="chapter">
    <div class="text-caption mb-1">{{$t('model.name.chapter')}} {{ chapter.sort }}</div>
    <div class="text-h5 mb-3">{{ chapter.name }}</div>
    <GridQuestion
      ref="grid_question"
      :chapter="chapter"
      :callbackError="showError"
    ></GridQuestion>
  </div>
</v-container>
</template>

<script>
  import DropdownChapter from '@/components/DropdownChapterV2'
  import DropdownSyllabus from '@/components/DropdownSyllabusV2'
  import GridQuestion from '@/components/GridQuestionV2'

  export default {
    components:{
      DropdownChapter,
      DropdownSyllabus,
      GridQuestion,
    },
    data:()=>({
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
      //BOC:[api]
      api:{
        isLoading:false,
        isError:false,
        error:null,
        url:null,
      },
      //EOC
      syllabus:null,
      chapter:null,
      questions:null,
    }),
    created() {
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("route.PageMainHome"),
        to:{name:'PageMainHome'},
        exact:true,
      })
      this.breadcrumbs.push({
        text:this.$t("route.PageMainDiscover"),
        to:{name:'PageMainDiscover'},
        exact:true,
      })
      //EOC
    },
    mounted() {
      this.$refs['dropdown_syllabus'].fetch()
    },
    methods:{
      showError(error) {
        this.api.isError = true
        this.api.error = error
      },
      selectSyllabus(item) {
        this.syllabus = item
        var that = this
        setTimeout(()=>{
          that.$refs['dropdown_chapter'].fetch()
        },100)
      },
      selectChapter(item) {
        this.chapter = item
        var that = this
        setTimeout(()=>{
          that.$refs['grid_question'].fetch()
        },100)
        
       window.history.pushState('','','?s='+this.syllabus.key+'&c='+this.chapter.key);
      },
    }
  }
</script>
