<template>
<div class="py-10 text-center">
  <div class="text-h5 mb-3">
    {{ $t("view.PageMainHome.slide_event_title") }}
  </div>
  <v-row>
    <v-col sm="3" v-for="event in events" :key="event.code" class="text-center">
      <div class="mb-1">
        <v-img
          contain
          :src="require('@/assets/PageMainHome/'+event.code+'.png')"
        ></v-img>
      </div>
      <div>{{event.term}}</div>
      <div class="text-h5">{{event.name}}</div>
      <div>{{event.start}} - {{event.end}}</div>
      <div>{{event.subject}}</div>
    </v-col>
  </v-row>
</div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      events:[
        {
          code:"Event5",
          name:"(No Activity)",
          term:"1st school holiday",
          // start:"2024/05/24",
          // end:"2024/06/02",
          // subject:null,
        },
        {
          code:"Event6",
          name:"Fishing Frenzy",
          term:"2nd school holiday",
          start:"2024/09/09",
          end:"2024/09/22",
          subject:"【 Maths 】",
        },
        {
          code:"Event7",
          name:"Hamochi ABC",
          term:"3rd school holiday",
          start:"2024/12/16",
          end:"2024/12/29",
          subject:"【 English 】",
        },
        {
          code:"Event8",
          name:"Discover Dino",
          term:"4th school holiday",
          start:"2025/01/13",
          end:"2025/01/26",
          subject:"【 Maths 】",
        },
      ],
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>