<template>
	<v-container fill-height fluid>
		<v-row 
			align="center"
			justify="center"
		>
			<v-col>
				<div class="text-center mb-3">
					<v-avatar size="150">
						<img
							alt="user"
							src="/images/Icon.png"
						>
					</v-avatar>
				</div>
				<!-- <v-card
					class="mx-auto mb-3"
					max-width="500"
				>
					<v-card-title>
						<v-avatar size="56">
							<img
								alt="user"
								src="/images/Icon.png"
							>
						</v-avatar>
						<div class="ml-3 d-flex justify-center">hamochigames.com</div>
					</v-card-title>
				</v-card> -->
				<v-card
					class="mx-auto"
					max-width="500"
				>
					<v-list>
						<v-subheader>Choose the language</v-subheader>
						<v-list-item-group>
							<v-list-item
								v-for="(item, i) in locales"
								:key="i"
								@click.stop="changeLocale(item)"
							>
								<v-list-item-content>
									<v-list-item-title v-text="item.name"></v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	export default {
		data:()=>({
			locales:[
				{
					name:'English',
					label:'EN',
					value:'en',
				},
				{
					name:'Bahasa Melayu',
					label:'BM',
					value:'ms',
				},
				{
					name:'中文',
					label:'中文',
					value:'zh',
				},
			],
		}),
		methods:{
			changeLocale:(locale)=>{
				window.location.href = window.location.href + locale.value + '/home'
			}
		},
	}
</script>
