<template>
<div class="py-10 text-center">
  <div class="text-h5 mb-3">
    {{ $t("view.PageMainHome.slide_testimony_parent_title") }}
  </div>
  <div>
    <v-img
      class="ma-auto"
      style="max-width:800px;"
      contain
      :src="require('@/assets/PageMainHome/TestimonyParent.png')"
    ></v-img>
  </div>
</div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>