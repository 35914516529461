<template>
<div class="py-10 text-center">
  <div class="text-h5 mb-3">
    {{ $t("view.PageMainHome.slide_video_title") }}
  </div>
  <iframe width="100%" style="max-width:560px;" height="315" src="https://www.youtube.com/embed/IRlYRjUzguY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>