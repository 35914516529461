<template>
  <v-select
    :label="$t('model.name.syllabus')"
    :placeholder="$t('model.name.syllabus')"
    class="white"
    :items="data"
    item-text="shortname"
    item-value="key"
    v-model="key"
    outlined
    hide-details
    :loading="api.isLoading"
  ></v-select>
</template>

<script>
export default {
  props: ["callbackSelect", "callbackError"],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    key: null,
    item: null,
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";
    this.api.url =
      this.$api.servers.question + "/v2/syllabi?lang=" + this.$_getLocale();
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      console.log(resp)
      if (this.$route.query.s) {
        this.key = this.$route.query.s;
        this.item = this.$_.find(this.data, { key: this.key });
      } else {
        this.item = resp[0];
        this.key = resp[0].key;
      }
    };
    //EOC
  },
  watch: {
    //BOC:[parent]
    key: function (val) {
      this.key = val;
      this.item = this.$_.find(this.data, { key: this.key });
      this.callbackSelect(this.item);
    },
    //EOC
  },
  mounted() {},
  methods: {
    fetch() {
      this.$api.fetch(this.api);
    },
  },
};
</script>
