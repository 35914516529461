<template>
<v-app>
  <v-app-bar 
    app
    color="primary"
    dark
  >
  <div v-if="setting&&setting.debug" class="debug-badge">Debug Mode</div>
    <v-app-bar-nav-icon class="d-flex d-sm-none" @click.stop="shown = !shown"></v-app-bar-nav-icon>
    <v-app-bar-nav-icon class="d-none d-sm-flex" @click.stop="minimised = !minimised"></v-app-bar-nav-icon>
    <SiteLogo></SiteLogo>
    <v-spacer></v-spacer>
    <v-btn
      :to="{'name':'PageMainHome'}"
      text
      class="mr-1 d-none d-sm-flex"
    >
      <span>{{ $t("route.PageMainHome") }}</span>
    </v-btn>
    <v-btn
      :to="{'name':'PageMainEvent'}"
      text
      class="mr-1 d-none d-sm-flex"
    >
      <span>{{ $t("route.PageMainEvent") }}</span>
    </v-btn>
    <v-btn
      :to="{'name':'PageMainDiscover'}"
      text
      class="mr-1 d-none d-sm-flex"
    >
      <span>{{ $t("route.PageMainDiscover") }}</span>
    </v-btn>
  </v-app-bar>
  <v-navigation-drawer 
    app
    v-model="shown"
    :mobile-breakpoint="600"
    class="d-flex d-sm-none"
  >
    <Menu></Menu>
  </v-navigation-drawer>
  <v-navigation-drawer 
    app
    :mini-variant="minimised"
    :mobile-breakpoint="600"
    class="d-none d-sm-flex"
  >
    <Menu></Menu>
  </v-navigation-drawer>
  <v-main>
    <router-view :key="$route.fullPath"/>
  </v-main>
</v-app>
</template>

<script>
  import Menu from './../components/MenuV1'
  import SiteLogo from './../components/SiteLogo'
  import { mapState } from "vuex";
  export default {
    components:{
      Menu,
      SiteLogo
    },
    data: () => ({
      shown: false,
      minimised:false,
    }),
    computed: mapState({
    setting: (state) => state.setting.data,
  }),
  mounted(){
    if(this.$route.query.debug && this.$route.query.debug == '1'){
      this.$store.commit("updateSetting", {debug : true});
    }else if(this.$route.query.debug && this.$route.query.debug != '1') {
      this.$store.commit("updateSetting", {debug : false});
    }
    this.setMaintenanceEndTime()
  },
  methods:{
    setMaintenanceEndTime() {
      const currentTime = this.$moment();
      const maintenanceStart = this.$moment().set({'hour':1,'minute':0,'second':0,'millisecond':0});
      const maintenanceEnd = this.$moment().set({'hour':23,'minute':0,'second':0,'millisecond':0});

      if (currentTime.isBetween(maintenanceStart, maintenanceEnd) || this.setting.debug) {
        return true
      } else{
        this.$router.push({name:'PageErrorUnderMaintenance'})
      }
    },
  }
  }
</script>
<style scoped>
.debug-badge {
  position: fixed;
  top: 0;
  left: 0;
  background-color: red;
  color: white;
  padding: 5px;
  font-size: 12px;
  z-index: 9999;
}
</style>